import { graphql } from 'gatsby'
import { EditorProvider } from '@tiptap/react'
import { Header, Footer } from '@/components'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import { Box } from '@mui/material'
import React from 'react'

const extensions = [
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Link.configure({
    HTMLAttributes: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  }),
]

const content = `
<h2 style="text-align:center">
  COOKIE POLICY
</h2>
<p/>
<p>
  <strong>IMILE DELIVERY BRAZIL LTDA.</strong>, registered under CNPJ: 47.173.294/0001-17, with address at Alameda Rio Negro, No. 585,<br/>Suite 51, 5th floor, Alphaville, Barueri/SP, ZIP Code: 06470-040, Brazil ("<strong>iMile</strong>").
</p>
<p>
  This Cookie Policy explains how <strong>iMile</strong> uses cookies and similar technologies to recognize you when you visit our website, <br/>including any mobile site or application ("Site"). It explains what these technologies are and why we use them, as well as <br/>your choices regarding their use.
</p>
<h3>
  WHAT ARE COOKIES?
</h3>
<p>
  Cookies are small text files that are stored on your browser or device by most websites you visit. They are widely used to<br/>
  make websites work, or work more efficiently, as well as to provide information to the website owners.
</p>
<h3>
  HOW WE USE COOKIES?
</h3>
<p>
  We use cookies for various reasons, detailed below. If you are unsure whether you need cookies or not, it is recommended<br/>
to keep them enabled, especially if they are used to provide a service that you use.
</p>
<h3>
  TYPES OF COOKIES WE USE
</h3>
<ul>
  <li>
    <strong>Necessary Cookies:</strong> These cookies are essential to provide you with services available through this Site and to use <br/>
    some of its features.
  </li>
  <li>
    <strong>Performance Cookies:</strong> These cookies are used to understand and analyze the performance and usage rates of the <br/>
    Site, helping to provide a better user experience.
  </li>
  <li>
    <strong>Functionality Cookies:</strong> These cookies are used to remember your preferences and help personalize your  <br/>
    experience.
  </li>
</ul>
<h3>
  COOKIE CONTROL
</h3>
<p>
  You can control and/or delete cookies as you wish within your browser settings. To learn generally about which cookies are <br/>
  installed on your device, or if you wish to delete or restrict them, use your browser's settings. You will find more explanations <br/>
  on how to proceed by clicking the links below. For information related to other browsers, visit the browser developer's <br/>
  website. Please note that if you choose to block the use of cookies, we cannot guarantee the correct functioning of the Site.
</p>
<p>
  <a href="https://support.mozilla.org/pt-BR/kb/limpe-cookies-e-dados-de-sites-no-firefox"> Firefox </a> |
  <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pt-BR"> Chrome </a> |
  <a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"> Safari </a> |
  <a href="https://support.microsoft.com/pt-br/topic/como-excluir-arquivos-de-cookie-no-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc"> Internet Explorer </a> |
  <a href="https://support.microsoft.com/pt-br/windows/gerenciar-cookies-no-microsoft-edge-exibir-permitir-bloquear-excluir-e-usar-168dab11-0753-043d-7c16-ede5947fc64d"> Edge 
  </a>
</p>
<h3>
  MORE INFORMATION
</h3>
<p>
  For more information on how we use your personal data, please refer to our Privacy Policy.
</p>
<h3>
  CHANGES TO THIS POLICY
</h3>
<p>
  We may update this Cookie Policy occasionally to reflect, for example, changes in the cookies we use or for other<br/>
  operational, legal, or regulatory reasons.
</p>
<h3>
  CONTACT US
</h3>
<p>
  If you have any questions about this Cookie Policy, please contact us: <a href="mailto:dpobrazil@imile.me">dpo@imile.me</a>
</p>
<p style="text-align:center"><strong>* * *</strong></p>
`

const CookiePolicyBrazil = (props) => {
  return (
    <>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      <Box pt={15} display='flex' alignItems='center' justifyContent='center'>
        <EditorProvider extensions={extensions} content={content} editable={false}></EditorProvider>
      </Box>
      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export default CookiePolicyBrazil

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["CookiePolicyBrazil"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
